<template>
  <div>
    <!-- list filters -->
    <v-card>
      <v-card-title>
        Vidéos
      </v-card-title>

      <v-divider class="mt-0"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="$store.state.video.field.keyword"
          placeholder="Rechercher par titre ou mot clé"
          label="Rechercher par titre ou mot clé"
          outlined
          hide-details
          dense
          clearable
          class="user-search me-3 mb-4"
          @click:clear.prevent="refreshVideo"
          @keyup="filterVideos"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-tooltip
            left
            color="primary"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="mb-4 me-3"
                small
                v-bind="attrs"
                @click.stop="openForm()"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPlus }}</v-icon>
              </v-btn>
            </template>
            <span>Nouvelle Video</span>
          </v-tooltip>

          <v-tooltip
            top
            color="secondary"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                color="secondary"
                class="mb-4 me-3"
                v-bind="attrs"
                small
                v-on="on"
                @click.prevent="refreshVideo"
              >
                <v-icon>{{ icons.mdiRefresh }}</v-icon>
              </v-btn>
            </template>
            <span>Raffraichir</span>
          </v-tooltip>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        loading-text="Chargement..."
        no-data-text="Aucun résultat"
        no-results-text="Aucun résultat"
        :headers="headers"
        :items="$store.getters['video/videos']"
        :items-per-page="$store.getters['video/per_page']"
        :page.sync="$store.getters['video/current_page']"
        dense
        fixed-header
        height="500"
        hide-default-footer
        :loading="loading"
        @page-count="currentPage = $event"
      >
        <template #[`item.created_at`]="{item}">
          {{ formatingDate(`${item.created_at}`) }}
        </template>
        <template #[`item.updated_at`]="{item}">
          {{ formatingDate(`${item.updated_at}`) }}
        </template>
        <template #[`item.deleted_at`]="{item}">
          <v-chip
            v-show="item.deleted_at !== null && item.deleted_at !== undefined"
            small
            color="error"
          >
            {{ item.deleted_at !== null ? formatingDate(`${item.deleted_at}`) : null }}
          </v-chip>
        </template>

        <template #[`item.with_push_notif`]="{item}">
          <v-chip
            small
            :color="item.with_push_notif === true ? 'success' : 'error'"
          >
            {{ item.with_push_notif === true ? "OUI" : "NON" }}
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link>
                <v-list-item-title
                  @click.stop="openForm(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                  <span>Modifier</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item link>
                <v-list-item-title @click.stop="showContentUrl(item.url)">
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiEye }}
                  </v-icon>
                  <span>Voir</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item link>
                <v-list-item-title
                  @click.stop="confirmItem(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ item.deleted_at !== null && item.deleted_at !== undefined? icons.mdiRestore : icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>{{ item.deleted_at !== null && item.deleted_at !== undefined? "Restaurer" : "Supprimer" }}</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item link>
                <v-list-item-title
                  @click.stop="confirmDeleteItem(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteForever }}
                  </v-icon>
                  <span>Supprimer définitivement</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <v-select
              v-model="$store.state.video.field.paginate"
              :items="itemsPerPage"
              item-text="text"
              item-value="value"
              label="Lignes par page:"
              type="number"
              min="-1"
              max="15"
              hide-details
              @input="getVideosPerPage"
            ></v-select>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="currentPage"
              total-visible="6"
              :length="lastPage"
              @input="getVideos"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <video-form
      :dialog="dialog"
      :object="item"
      @clickout="dialog = false"
      @submitted="loading"
    />
    <confirm-dialog
      :message="messageDialog"
      :dialog-delete="dialogDelete"
      @clickout="dialogDelete = false"
      @submitted="deleteItem"
    />

    <link-content-dialog
      :dialog-content="showDialogContent"
      :url="contentUrl"
      @clickout="closeContentUrl"
    />
  </div>
</template>

<script>
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiRefresh,
  mdiPencilOutline,
  mdiRestore,
  mdiEye,
  mdiDeleteForever,
} from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import { customFormatDate } from '@core/utils/filter'
import VideoForm from '@core/components/video/VideoForm'
import ConfirmDialog from '@core/components/dialogs/ConfirmDialog'
import { notify } from '@core/utils/notification'
import LinkContentDialog from '@core/components/dialogs/LinkContentDialog'
import store from '@/store'

export default {
  name: 'Videos',
  components: { LinkContentDialog, ConfirmDialog, VideoForm },
  setup() {
    const loading = ref(false)
    const dialogDelete = ref(false)
    const message = ref(null)
    const currentPage = ref(null)
    const lastPage = ref(null)
    const error = ref(null)
    const isAddNewVideoSidebarActive = ref(false)
    const messageDialog = ref('')
    const showDialogContent = ref(false)
    const forcedelete = ref(false)
    const itemsPerPage = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
    ]
    const dialog = ref(false)
    const item = ref({})

    const openForm = val => {
      if (val) {
        item.value = {
          id: val.id,
          title: val.title,
          url: val.url,
          user_id: val.user_id,
          with_push_notif: val.with_push_notif,
          prefix: val.url.split('://')[0],
          surfix: val.url.split('://')[1],
        }
      } else {
        item.value = {
          id: null,
          title: null,
          url: null,
          user_id: null,
          with_push_notif: null,
          prefix: null,
          surfix: null,
        }
      }
      dialog.value = true
    }
    const contentUrl = ref(null)

    const showContentUrl = val => {
      contentUrl.value = val
      showDialogContent.value = true
    }

    const closeContentUrl = () => {
      contentUrl.value = null
      showDialogContent.value = false
    }

    const getVideos = (page = 1) => {
      store.state.video.field.user = null
      store.state.video.field.user_id = null
      loading.value = true
      store.dispatch('video/getVideos', { page, field: store.state.video.field }).then(() => {
        currentPage.value = store.getters['video/current_page']
        lastPage.value = store.getters['video/last_page']
        loading.value = false
        // eslint-disable-next-line no-shadow
      }).catch(errors => {
        message.value = errors.response.data.message
        error.value = errors.response.data.message
        loading.value = false
      })
    }

    const refreshVideo = (page = 1) => {
      loading.value = true
      store.state.video.field.title = null
      store.state.video.field.keyword = null
      store.dispatch('video/refreshVideo', { page, field: store.state.video.field }).then(() => {
        currentPage.value = store.getters['video/current_page']
        lastPage.value = store.getters['video/last_page']
        loading.value = false
        // eslint-disable-next-line no-shadow
      }).catch(errors => {
        message.value = errors.response.data.message
        error.value = errors.response.data.message
        loading.value = false
      })
    }

    // eslint-disable-next-line camelcase
    const getVideosPerPage = per_page => {
      loading.value = true
      store.state.video.field.paginate = per_page
      store.dispatch('video/getVideos', { page: store.getters['video/current_page'], field: store.state.video.field }).then(() => {
        currentPage.value = store.getters['video/current_page']
        lastPage.value = store.getters['video/last_page']
        loading.value = false
        // eslint-disable-next-line no-shadow
      }).catch(errors => {
        message.value = errors.response.data.message
        error.value = errors.response.data.message
        loading.value = false
      })
    }

    const filterVideos = () => {
      setTimeout(() => {
        getVideos(store.getters['video/current_page'])
      }, 1000)
    }

    const formatingDate = date => customFormatDate(date, {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    })

    const confirmItem = object => {
      messageDialog.value = object.deleted_at === null || item.value.deleted_at === undefined ? `Voulez-vous supprimer la video ${object.title}?` : `Voulez-vous restaurer la video ${object.title}?`
      item.value = object
      dialogDelete.value = true
      forcedelete.value = false
    }

    const confirmDeleteItem = object => {
      messageDialog.value = `Voulez-vous supprimer définitivement la video ${object.title}?`
      item.value = object
      dialogDelete.value = true
      forcedelete.value = true
    }

    const deleteItem = () => {
      if (forcedelete.value === false) {
        if (item.value.deleted_at === null || item.value.deleted_at === undefined) {
          store.dispatch('video/deleteVideo', item.value).then(response => {
            dialogDelete.value = false
            notify(true, true, response.message)
          }).catch(err => {
            message.value = err.response.data.message
            error.value = err.response.data.message
            dialogDelete.value = false
            notify(true, true, err.response.data.message)
          })
        } else {
          store.dispatch('video/restoreVideo', item.value).then(response => {
            dialogDelete.value = false
            notify(true, true, response.message)
          }).catch(err => {
            message.value = err.response.data.message
            error.value = err.response.data.message
            dialogDelete.value = false
            notify(true, true, err.response.data.message)
          })
        }
      } else {
        store.dispatch('video/forceDeleteVideo', item.value).then(response => {
          dialogDelete.value = false
          notify(true, true, response.message)
        }).catch(err => {
          message.value = err.response.data.message
          error.value = err.response.data.message
          dialogDelete.value = false
          notify(true, true, err.response.data.message)
        })
      }
    }

    onMounted(() => {
      getVideos(store.getters['video/current_page'])
    })

    return {
      closeContentUrl,
      showContentUrl,
      contentUrl,
      showDialogContent,
      forcedelete,
      confirmDeleteItem,
      deleteItem,
      confirmItem,
      messageDialog,
      dialogDelete,
      dialog,
      openForm,
      item,
      refreshVideo,
      getVideos,
      formatingDate,
      getVideosPerPage,
      filterVideos,
      isAddNewVideoSidebarActive,
      loading,
      error,
      currentPage,
      lastPage,
      message,
      itemsPerPage,
      headers: [
        { text: 'TITRE', value: 'title', sortable: false },
        { text: 'MOTS CLES', value: 'keywords', sortable: false },
        { text: 'AVEC NOTIFICATION PUSH', value: 'with_push_notif', sortable: false },
        { text: 'DATE DE CREATION', value: 'created_at', sortable: false },
        { text: 'DATE DE MISE A JOUR', value: 'updated_at', sortable: false },
        { text: 'DATE DE SUPPRESSION', value: 'deleted_at', sortable: false },
        { text: 'ACTIONS', value: 'actions', sortable: false },
      ],
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiRefresh,
        mdiPencilOutline,
        mdiRestore,
        mdiEye,
        mdiDeleteForever,
      },
    }
  },
}
</script>

<style scoped>
.v-data-table-header th {
  white-space: nowrap;
}
</style>
